<template>
<div class="vs-con-loading__container">
    <h2>Empresa <router-link :to="'/'+client.uri" class="link">(ver)</router-link></h2>
    <div class="box" v-if="!client.name">
        <h2>Empresa no encontrada</h2>
    </div>
    <div class="box vs-con-loading__container" v-if="client.name">
        <vs-row vs-type="flex" vs-align="flex-end" vs-justify="space-between">
            <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="3">
                <vs-input label-placeholder="Nombre" v-model="client.name"/>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="2">
                <vs-input label-placeholder="Ruta interna" v-model="client.uri" icon="trash" icon-pack="fas fa-slash flip-slash" />
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="3">
                <!-- <vs-input label-placeholder="Email admin (debe ser Gmail)" v-if="!tenantMode" v-model="client.email" icon=".." icon-pack="fab fa-google"/> -->
            </vs-col>
            <vs-col vs-justify="right" vs-align="center" vs-w="2">
                <span class="slider-label block" style="margin-bottom:13px" v-if="!tenantMode">Empresa activa?</span>
                <vs-switch v-model="client.active" v-if="!tenantMode">
                </vs-switch>
            </vs-col>
        </vs-row><br>

        <vs-row vs-type="flex" vs-align="flex-end" vs-justify="space-between" vs-w="12">
            <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="3">
                <vs-input label-placeholder="URL Google" v-model="client.googleUrl" icon=".." icon-pack="fab fa-google"/>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="3" v-if="!tenantMode" class="red-label">
                <vs-input label-placeholder="(deshabilitado) URL Facebook" v-model="client.facebookUrl"  icon=".." icon-pack="fab fa-facebook"/>
            </vs-col>
            <vs-col  vs-justify="left" vs-align="center" vs-w="3" v-if="!tenantMode">
                <span class="slider-label red">(deshabilitado) Probabilidad de Google: {{client.googlePreference}}%</span>
                <vs-slider v-model="client.googlePreference" text-fixed="%"/>
            </vs-col>
            <vs-col  vs-justify="left" vs-align="center" vs-w="1">
            </vs-col>
        </vs-row><br>

        <vs-row vs-type="flex" vs-align="flex-end" vs-justify="space-between" vs-w="12">
            <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="3">
                <vs-input label="Color de texto" type="color" v-model="client.textColor"/>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="3">
                <vs-input label="Color de fondo" type="color" v-model="client.backgroundColor"/>
            </vs-col>
            <vs-col  vs-justify="left" vs-align="center" vs-w="3" class="uploader-mini">
                <div class="uploader-mini">
                    <vs-row vs-type="flex" vs-align="flex-end" vs-justify="space-between" vs-w="12">
                       <vs-col vs-justify="left" vs-align="center" vs-w="11" class="uploader-mini">
                            <span class="slider-label">Logo de empresa</span> 
                            <div class="logo-container">
                                <img :src="client.logo" class="client-logo" ref="currentLogo">
                                <vs-upload action="" ref="logo" @change="setupFile" text="Seleccionar.." nolimit="1" accept="jpg,png,jpeg,gif"/>
                            </div>
                       </vs-col>
                       <vs-col  vs-justify="right" vs-align="center" vs-w="1" class="uploader-mini">
                            <vs-input v-model="client.logoWidth" label-placeholder="Ancho máximo" style="width:95px"/>
                            <vs-input v-model="client.logoMargin" label-placeholder="Margen" style="width:95px"/>
                       </vs-col>
                    </vs-row>
                </div>
            </vs-col>
            <vs-col  vs-justify="left" vs-align="center" vs-w="1">
            </vs-col>
        </vs-row><br><br>

        <vs-row vs-type="flex" vs-align="flex-start" vs-justify="space-between" vs-w="12">
            <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="3">
                <vs-input label-placeholder="Título en pantalla" v-model="client.title"/>
            </vs-col>
            <vs-col novs-type="flex" vs-justify="left" vs-align="center" vs-w="3">
                <div class="slider-label" sxtyle="padding-top:30px">Descripción en pantalla</div>
                <vs-textarea label-placeholder="URL Google" v-model="client.description"/>
            </vs-col>
            <vs-col novs-type="flex" vs-justify="left" vs-align="center" vs-w="3">
                <div class="slider-label" sxtyle="padding-top:30px">Mensaje para pedir opinión</div>
                <vs-textarea label-placeholder="URL Google" v-model="client.lowRatingMessage"/>
            </vs-col>
            <vs-col  vs-justify="left" vs-align="center" vs-w="1">
            </vs-col>
        </vs-row><br>

        <vs-row vs-type="flex" vs-align="flex-start" vs-justify="space-between" vs-w="12">
            <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="3">
                <vs-input label-placeholder="Título con nombre (%n)" v-model="client.namedTitle"/>
            </vs-col>
            <vs-col novs-type="flex" vs-justify="left" vs-align="center" vs-w="3">
                <vs-input label-placeholder="Frase al enviar feedback" v-model="client.thanks"/>
            </vs-col>
            <vs-col novs-type="flex" vs-justify="left" vs-align="center" vs-w="3">
                <vs-input label-placeholder="Email destinatario de feedback" v-model="client.feedbackEmail"/>
            </vs-col>
            <vs-col  vs-justify="left" vs-align="center" vs-w="1">
            </vs-col>
        </vs-row><br>


    </div>
    <vs-row vs-justify="center" class="mt-20" v-if="client.name">
        <vs-button size="large" @click="btnLoader($event, save)">Guardar cambios</vs-button>
    </vs-row>

</div>
</template>

<script>
/* eslint-disable */
import btnLoader from './btnLoader'
import { mapGetters, mapMutations } from 'vuex'
import { onSnapshot, collection, db, setDoc, addDoc, doc, orderBy, uploadBytes, getDownloadURL,
         ref, storage, query, getDoc, getDocs, deleteDoc } from '../firebase'

export default {
    data() {
        return {
            client: { name: '.' },
            logoUploadData: {},
            tenantMode: '..',
        }
    },
    methods: {
        async save(stopLoader) {
            if (this.$refs.logo.filesx[0]) {
                console.log(`subiendo logo..`, );
                this.client.logo = await this.uploadFile();
                //guardar tamaño logo
                this.client.logoNaturalWidth = document.querySelector('.img-upload img').naturalWidth;
                this.client.logoNaturalHeight = document.querySelector('.img-upload img').naturalHeight;
            }
            setDoc(doc(db, "clients", this.$route.params.id), this.client).then(client=>{
                stopLoader(true);
            }).catch(err => {
                console.log(err);
                alert('No se pudo guardar la empresa');
                stopLoader(true);
            });
        },
        guid() {
            return 'xxxxxxxx-xxxx-xxx-xxx-xxx'.replace(/[xy]/g, function (c) {
                // eslint-disable-next-line no-mixed-operators
                const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
                return v.toString(16)
            })
        },
        setupFile() {
            if (this.$refs.logo.filesx[1]) {
                this.$refs.logo.filesx.shift();
                this.$refs.logo.removeFile(0); //quitar archivo anterior
            }
            this.$refs.currentLogo.style.display = 'none';
            this.logoUploadData.file = this.$refs.logo.filesx[0];
            this.logoUploadData.name = `${this.$route.params.id}/${this.guid()}-${this.logoUploadData.file.name}`;
            this.logoUploadData.storageRef = ref(storage, this.logoUploadData.name);
        },
        async uploadFile () {
            await uploadBytes(this.logoUploadData.storageRef, this.logoUploadData.file);
            const url = await getDownloadURL(this.logoUploadData.storageRef);
            return url;
        },
    },
    async mounted() {
        this.btnLoader = btnLoader(this);
        if (!this.$route.params.id || this.$route.params.allowReload) { //hack para reloadear tenant
            this.tenantMode = true;
            this.$route.params.allowReload = true;
            this.$route.params.id = this.$store.state.user.currentTenant;
        }
        else {
            this.tenantMode = false;
        }
        getDoc(doc(db, "clients", this.$route.params.id)).then(doc => {
            this.client = doc.data() || {};
        }).catch(err => {
            console.log(err);
        })
    },
}
</script>

<style>
.red, .red-label .vs-input--placeholder { color:red !important }
.link { color: rgba(var(--vs-primary),1)!important; }
.client-logo {
    height: 32px !important;
    float: left;
    border-radius: 5px;
}
.logo-container {
    display: flex;
}
</style>